import * as ExperimentActions from '@redux/experiments';
import { vendorRecommendationDistanceSelector } from '@redux/experiments/selectors/vendor-recommendation-distance';
import { VRMAIPhotoSelector } from '@redux/experiments/selectors/vrm-ai-photo';
import { getSimilarVendors as getSimilarVendorsAction } from '@redux/vrm/thunks';
import { experiments } from '@settings';
import { State } from '@typings/redux';
import { noop } from '@utils/noop';
import React, {
	createContext,
	FC,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { connect } from 'react-redux';
import { InlineRfqSourceContent } from 'types/sourceContent';
import { Raw, Similar } from 'types/vendor';
import { GetSimilarVendors } from '../../pages/VendorsSearch/containers/SearchResults/components/RFQModal/components/RFQModal.d';
import {
	VRM_VENDORS_REQUESTED,
	filterVendors,
	selectUserMaxGuestCount,
} from '../../pages/VendorsSearch/containers/SearchResults/components/RFQModal/components/utils';

export interface RecommendedContextPropsValue {
	modal: string;
	vrmOpenedFrom: string;
	onClose: () => void;
	recommendedVendors: Similar[];
	setModal: (modal: string) => void;
	openModal: (sourceContent: InlineRfqSourceContent) => void;
	initiator:
		| {
				sourceContent: InlineRfqSourceContent;
		  }
		| Record<string, unknown>;
	userMaxGuestCount?: number;
	vendorRecommendationDistanceAssignment?: string;
	vrmAIPhotoAssignment?: string;
}

type StateProps = ReturnType<typeof mapStateToProps>;
interface DispatchProps {
	getSimilarVendors: GetSimilarVendors;
	reportServerSideExperiment: (idExperiment: Experiments.Id) => void;
}
type RecommendedContextProps = StateProps & DispatchProps;

const RecommendedContext = createContext<RecommendedContextPropsValue>({
	modal: '',
	vrmOpenedFrom: '',
	onClose: noop,
	recommendedVendors: [],
	openModal: noop,
	setModal: noop,
	initiator: {},
	userMaxGuestCount: 0,
	vendorRecommendationDistanceAssignment: '',
	vrmAIPhotoAssignment: '',
});

const ContextProvider: FC<RecommendedContextProps> = (props) => {
	const {
		children,
		getSimilarVendors,
		messagedVendors,
		pageType,
		searchLocation,
		searchPageLocation,
		similarVendors,
		vendorRaw,
		userMaxGuestCount,
		vendorRecommendationDistanceAssignment,
		vrmAIPhotoAssignment,
		reportServerSideExperiment,
	} = props;
	const [modal, setModal] = useState('');
	const [vrmOpenedFrom, setvrmOpenedFrom] = useState('');
	const [initiator, setInitiator] = useState<
		{ sourceContent: InlineRfqSourceContent } | Record<string, unknown>
	>({});

	const recommendedVendors = useMemo(() => {
		return filterVendors(messagedVendors, similarVendors, userMaxGuestCount);
	}, [messagedVendors, similarVendors, userMaxGuestCount]);

	useEffect(() => {
		if (vendorRecommendationDistanceAssignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log(
				'Post Vendor Recommendation Distance Assignment:',
				vendorRecommendationDistanceAssignment,
			);
			reportServerSideExperiment(experiments.vendorRecommendationDistance);
		}
		const radius =
			vendorRecommendationDistanceAssignment === '70-miles' ? 70 : 25;

		const vendorsRequested = VRM_VENDORS_REQUESTED;

		if (vrmAIPhotoAssignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log('VRM Smart Main Image: ', vrmAIPhotoAssignment);
			reportServerSideExperiment(experiments.VRMAIPhoto);
		}

		getSimilarVendors(
			vendorRaw,
			vendorsRequested,
			radius,
			searchLocation,
			searchPageLocation,
			pageType,
			vrmAIPhotoAssignment || undefined,
		);
	}, [
		getSimilarVendors,
		vendorRaw,
		searchLocation,
		searchPageLocation,
		pageType,
		reportServerSideExperiment,
		vendorRecommendationDistanceAssignment,
		vrmAIPhotoAssignment,
	]);

	// biome-ignore lint/correctness/useExhaustiveDependencies: dependencies are correct
	const openModal = useCallback(
		(sourceContentValue: InlineRfqSourceContent) => {
			setInitiator({
				sourceContent: sourceContentValue,
			});
			setModal('vrm');
			setvrmOpenedFrom(sourceContentValue);
		},
		[setModal, setInitiator],
	);

	const onClose = useCallback(() => {
		setModal('');
		setvrmOpenedFrom('');
	}, []);

	const value = useMemo(
		() => ({
			modal,
			openModal,
			setModal,
			onClose,
			recommendedVendors,
			initiator,
			vrmOpenedFrom,
		}),
		[modal, recommendedVendors, onClose, openModal, initiator, vrmOpenedFrom],
	);

	return (
		<RecommendedContext.Provider value={value}>
			{children}
		</RecommendedContext.Provider>
	);
};

const mapStateToProps = (state: State) => ({
	messagedVendors: state.messagedVendors.conversations,
	pageType: state.page.pageType,
	searchLocation: state.location,
	searchPageLocation: state.searchPageLocation,
	similarVendors: state.vrm.similarVendors,
	vendorRaw: state.vendor.vendorRaw as Raw,
	userMaxGuestCount: selectUserMaxGuestCount(state),
	vendorRecommendationDistanceAssignment:
		vendorRecommendationDistanceSelector(state),
	vrmAIPhotoAssignment: VRMAIPhotoSelector(state),
});

const mapDispatchToProps = {
	getSimilarVendors: getSimilarVendorsAction,
	reportServerSideExperiment: ExperimentActions.reportServerSideExperiment,
};

const RecommendedContextProvider = connect(
	mapStateToProps,
	mapDispatchToProps,
)(ContextProvider);

export { RecommendedContextProvider };

export default RecommendedContext;
