import { compose } from '@xo-union/react-css-modules';
import { Button } from '@xo-union/tk-component-buttons';
import { Field } from '@xo-union/tk-component-fields';
import React, { VFC, ChangeEvent, useState } from 'react';
import usePassword from '../../hooks/usePassword/usePassword';
import type { Analytics } from '../../types';
import styles from './PasswordDialog.scss';

const passwordClasses = compose({
	container: styles.passwordContainer,
	'sub-text': styles.passwordSubtext,
});

export interface PasswordDialogProps {
	closeCallback: (analytics: Analytics) => void;
	changePasswordStatus: Redux.Rfq['changePasswordStatus'];
	handleSubmit: (analytics: Analytics) => void;
	updatePassword: (password: string) => void;
	submitAnalytics: Analytics;
	password: string;
}

const PasswordDialog: VFC<PasswordDialogProps> = (props) => {
	const { password, submitAnalytics, changePasswordStatus, handleSubmit } =
		props;

	const [passwordRef, handlePasswordChange, passwordState] = usePassword(props);
	const [isPasswordValid, setIsPasswordValid] = useState('neutral');
	const [submitEmpty, setSubmitEmpty] = useState(false);

	const handleSavePassword = () => {
		if (password.length === 0) {
			setSubmitEmpty(true);
		}
		const passwordValid = passwordState(false);
		setIsPasswordValid(passwordValid);
		if (passwordValid === 'neutral') {
			handleSubmit(submitAnalytics);
		}
	};

	const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
		handlePasswordChange(event);
		const passwordValid = passwordState(false);
		setIsPasswordValid(passwordValid);
		if (password.length !== 0) {
			setSubmitEmpty(false);
		}
	};

	return (
		<div className={styles.container}>
			<Field
				classes={passwordClasses}
				inputRef={passwordRef}
				label="Password"
				type="password"
				name="userPassword"
				onChange={handleFieldChange}
				state={
					changePasswordStatus.changeSuccessful === 'errored' ||
					submitEmpty ||
					(password.length > 0 && password.length < 6)
						? 'invalid'
						: passwordState()
				}
				subText={
					password.length === 0 && isPasswordValid === 'invalid'
						? 'Please enter a password.'
						: password.length > 0
						  ? 'Please enter 6-20 characters.'
						  : null
				}
				value={password}
			/>
			<div className={styles.buttonContainer}>
				<Button
					color="primary"
					size="lg"
					onClick={handleSavePassword}
					className={styles.buttonContainer}
				>
					Save Password
				</Button>
			</div>
		</div>
	);
};

export default PasswordDialog;
