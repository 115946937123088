import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { Body2, Caption } from '@xo-union/tk-ui-typography';
import React, { FC } from 'react';
import PictureThumbnail from '../../components/PictureThumbnail';
import { ACTION_CLICK_VENDOR, TRACKING_REASON } from '../../utils/constants';
import { trackConfirmationModalCtaClick } from '../../utils/tracking';
import Styles from './RoundVendorCard.scss';

export type RoundVendorCardVendor = API.FESharedProfile;
interface RoundVendorCardProps {
	vendor: RoundVendorCardVendor;
	sourcePage: string;
}

export const RoundVendorCard: FC<RoundVendorCardProps> = ({
	vendor,
	sourcePage,
}) => {
	const shouldRenderQuickResponderCaption = Boolean(
		vendor?.vendorBehavior?.quickResponder,
	);

	const shouldRenderImage = Boolean(vendor?.storefrontCard?.url !== null);

	const { track } = useAnalyticsContext();
	const trackProperties = {
		source_page: sourcePage,
		action: ACTION_CLICK_VENDOR,
		selection: vendor.name,
		reason: TRACKING_REASON,
	};

	return (
		<>
			{/* biome-ignore lint/a11y/useValidAnchor: <explanation> */}
			<a
				href={vendor.siteUrls[0].uri}
				target="_blank"
				rel="noreferrer"
				onClick={() => {
					trackConfirmationModalCtaClick(track, trackProperties);
				}}
			>
				<div className={Styles.cardContainerRow}>
					<div className={Styles.imageContainer}>
						{(shouldRenderImage && (
							<img src={vendor?.storefrontCard?.url} alt="Vendor profile" />
						)) || <PictureThumbnail />}
					</div>
					<div className={Styles.infoContainer}>
						<Body2>{vendor.name}</Body2>
						{shouldRenderQuickResponderCaption && (
							<Caption className={Styles.responseTime}>
								Typically responds within 24 hours
							</Caption>
						)}
					</div>
				</div>
			</a>
		</>
	);
};
