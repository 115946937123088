import { leadFormSequencingSelector } from '@redux/experiments/selectors/lead-form-sequencing';
import type { LeadFormSequencing_v1_1_Assignment } from '@typings/experiments';
import { Body2 } from '@xo-union/tk-ui-typography';
import React from 'react';
import { connect } from 'react-redux';
import type { State } from 'types/redux';

import { Member } from '@typings/membership';
import Styles from './styles.scss';

export interface RfqLegalCopyProps {
	leadFormSequencing_v1_1Assignment?: LeadFormSequencing_v1_1_Assignment;
	btnText?:
		| 'Submit'
		| 'Request Quote'
		| 'Schedule Appointment'
		| 'Send'
		| 'Send request';
}

export const RfqLegalCopy = (props: RfqLegalCopyProps) => {
	const { btnText = 'Request Quote', leadFormSequencing_v1_1Assignment } =
		props;

	if (leadFormSequencing_v1_1Assignment?.startsWith('variant1')) {
		return (
			<Body2
				className={`${Styles.legalCopy} ${Styles.legalCopyVRMOnDExp}`}
				id="terms-and-conditions"
			>
				{`
				By clicking '${btnText}', you agree to your requests being shared with the selected vendors and `}
				<a
					href="https://www.theknotww.com/terms-of-use/"
					rel="noopener noreferrer"
					target="_blank"
				>
					The Knot terms
				</a>
				.
			</Body2>
		);
	}

	return (
		<Body2 className={Styles.legalCopy} id="terms-and-conditions">
			{`By clicking '${btnText}', you agree to The Knot creating an account for you, if you don't have one, and to our `}
			<a
				href="https://www.theknotww.com/terms-of-use"
				rel="noopener noreferrer"
				target="_blank"
				className={Styles.link}
			>
				Terms of Use
			</a>
			. See our{' '}
			<a
				href="https://www.theknotww.com/privacy-policy"
				rel="noopener noreferrer"
				target="_blank"
				className={Styles.link}
			>
				Privacy Policy
			</a>{' '}
			to learn what data we collect and how we use it.
		</Body2>
	);
};

const mapStateToProps = (state: State) => ({
	leadFormSequencing_v1_1Assignment: leadFormSequencingSelector(state),
});

export default connect(mapStateToProps)(RfqLegalCopy);
