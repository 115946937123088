import { TrackConfModalViewedProperties } from '@components/auto-account-creation/types';
import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { DisplayLink } from '@xo-union/tk-ui-links';
import React from 'react';
import { ACTION_CLICK_INBOX } from '../../utils/constants';
import { trackConfirmationModalCtaClick } from '../../utils/tracking';
import { INBOX_URL } from '../settings';

interface InboxLinkProps {
	setCtaTrackProperties: (
		action: string,
		selection: string,
	) => TrackConfModalViewedProperties;
}

export const InboxLink: React.FC<InboxLinkProps> = ({
	setCtaTrackProperties,
}) => {
	const { track } = useAnalyticsContext();

	return (
		<DisplayLink
			href={INBOX_URL}
			color="secondary"
			size="lg"
			target="_blank"
			onClick={() =>
				trackConfirmationModalCtaClick(
					track,
					setCtaTrackProperties(ACTION_CLICK_INBOX, 'See your vendor inbox'),
				)
			}
		>
			See your vendor inbox
		</DisplayLink>
	);
};
