import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { DisplayLink } from '@xo-union/tk-ui-links';
import React, { FC } from 'react';
import PictureThumbnail from '../../components/PictureThumbnail';
import {
	ACTION_CLICK_VENDOR,
	TRACKING_REASON,
	VRM_CONFIRMATION_NEW_USER_MODAL_TYPE,
} from '../../utils/constants';
import { trackConfirmationModalCtaClick } from '../../utils/tracking';
import Styles from './RoundVendorCard.scss';

export type RoundVendorCardVendor = API.FESharedProfile;
interface RoundVendorCardProps {
	vendor: RoundVendorCardVendor;
	sourcePage: string;
	isSingleVendor?: boolean;
}

interface VendorCardFrameProps {
	shouldRenderImage: boolean;
	vendor: RoundVendorCardVendor;
	isLarge?: boolean;
}

const VendorCardFrame: FC<VendorCardFrameProps> = ({
	shouldRenderImage,
	vendor,
	isLarge = false,
}) => {
	return (
		<div
			className={isLarge ? Styles.largeVendorCardFrame : Styles.vendorCardFrame}
		>
			<div className={Styles.imageContainer}>
				{(shouldRenderImage && (
					<img src={vendor?.storefrontCard?.url} alt="Vendor profile" />
				)) || <PictureThumbnail />}
			</div>
			<div className={Styles.vendorName}>{vendor.name}</div>
		</div>
	);
};

export const RoundVendorCard: FC<RoundVendorCardProps> = ({
	vendor,
	sourcePage,
	isSingleVendor = false,
}) => {
	const shouldRenderImage = Boolean(vendor?.storefrontCard?.url !== null);

	const { track } = useAnalyticsContext();
	const trackProperties = {
		source_page: sourcePage,
		action: ACTION_CLICK_VENDOR,
		selection: vendor.name,
		reason: TRACKING_REASON,
		sourceContent: VRM_CONFIRMATION_NEW_USER_MODAL_TYPE,
	};

	return !isSingleVendor ? (
		<DisplayLink
			href={vendor.siteUrls[0].uri}
			target="_blank"
			rel="noreferrer"
			onClick={() => {
				trackConfirmationModalCtaClick(track, trackProperties);
			}}
		>
			<VendorCardFrame shouldRenderImage={shouldRenderImage} vendor={vendor} />
		</DisplayLink>
	) : (
		<VendorCardFrame
			shouldRenderImage={shouldRenderImage}
			vendor={vendor}
			isLarge={isSingleVendor}
		/>
	);
};
